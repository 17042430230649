import { ModalWindow } from "../../UI/ModalWindow";
import { Input, Select } from '../../UI/FormElements'

const ROUTE_URL = "/incident_type";
const initialData = { name: "", status: "Отказ" };

function ModalIncidentTypes({ params, handleClose }) {
  let urlSubmit, urlGet;
  if (params.isNew) {
    urlSubmit = ROUTE_URL;
    urlGet = ""; //или ROUTE_URL + "/create" если при создании НАДО предварительное обращение к серверу

  }
  else {
    urlGet = ROUTE_URL + "/" + params.id;
    urlSubmit = urlGet;

  }

  return (
    <ModalWindow params={params} handleClose={handleClose} initialData={initialData} urlGet={urlGet} urlSubmit={urlSubmit} >
      <IncidentCardsFields />
    </ModalWindow>
  );
}
let categoryTypes = [{ id: 1, name: "Отказ" }, { id: 2, name: "Авария" }, { id: 3, name: "Сбой" }, { id: 4, name: "Перерыв по связи" }];

const IncidentCardsFields = ({ dopParams, ...elementProps }) => {
  return (
    <div className="row form-gutter">
      <div className="col-12">
        <Input label="Наименование" name="name"  {...elementProps} required autoFocus />
      </div>
      <div className="col-12">
      <Select label="Состояние" name="status" {...elementProps} selectOptions={categoryTypes} required />
      </div>
    </div>
  )
}
export default ModalIncidentTypes;
