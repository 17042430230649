import { ModalWindow } from "../../UI/ModalWindow";
import { Input, Select } from '../../UI/FormElements'

const ROUTE_URL = "/alarm_b";
const initialData = { owner: "", active: "0", address: ""};

function ModalAlarmButtons({ params, handleClose }) {
  let urlSubmit, urlGet;
  if (params.isNew) {
    urlSubmit = ROUTE_URL;
    urlGet = ""; //или ROUTE_URL + "/create" если при создании НАДО предварительное обращение к серверу

  }
  else {
    urlGet = ROUTE_URL + "/" + params.id;
    urlSubmit = urlGet;

  }

  return (
    <ModalWindow params={params} handleClose={handleClose} initialData={initialData} urlGet={urlGet} urlSubmit={urlSubmit} >
      <IncidentCardsFields />
    </ModalWindow>
  );
}
// let categoryTypes = [{ id: 1, name: "Отказ" }, { id: 2, name: "Авария" }, { id: 3, name: "Сбой" }, { id: 4, name: "Перерыв по связи" }];

const IncidentCardsFields = ({ dopParams, ...elementProps }) => {
  return (
    <div className="row form-gutter">
      <div className="col-12 rowcols">
        <div className="rowcol">
          <Input label="Владелец" name="owner" {...elementProps} required />
        </div>
        <div className="rowcol checkbox">
          <Input type="checkbox" className="form-check-input actcheck" label="Включен" name="active" {...elementProps} onChange={(e) => e.target.checked ? elementProps.data.active = 1 : elementProps.data.active = 0} />
        </div>
      </div>
      <div className="col-12">
        <Input label="Адрес" name="address"  {...elementProps} required autoFocus />
      </div>
    </div>
  )
}
export default ModalAlarmButtons;
